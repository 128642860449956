import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PhysicalDamage from '../../Section/PhysicalDamage/PhysicalDamage';
import Cargo from '../../Section/Cargo/Cargo';
import NonTrucking from '../../Section/Non-Trucking/NonTrucking';
import { getRequest } from '../../../../../../api/api';
import { GetGuestUserToken } from '../../../guestUserTokenUtils';
import {
  GET_POLICY_DETAILS,
  GET_PD_PREMIUM,
  GET_CG_PREMIUM,
} from '../../../../../../api/endpoint';

const NTCGPD = ({
  toggleSecondAccordion,
  setPolicyData,
  policyData,
  getSubscriptionFor,
  saveProfile,
  activeTabNTCGPD,
  setActiveTabNTCGPD,
  handleTabChangeNTCGPD,
  openFirstAccordion,
  handleModalShow,
  modalOpen,
  setModalOpen,
  isDirectCheckout,
  setCurrentAccordion,
}) => {
  const [PDpolicyListingRepsonse, setPDPolicyListingRepsonse] = useState(null);
  const [CGpolicyListingRepsonse, setCGPolicyListingRepsonse] = useState(null);
  const [NTpolicyListingRepsonse, setNTPolicyListingRepsonse] = useState(null);

  const showPhysicalDamage = false; 
  const showCargo = false; 
  
  useEffect(() => {
    if (showPhysicalDamage && activeTabNTCGPD === 'physicalDamage') {
      PDPolicyDetailsListing();
      fetchPDPremium(getSubscriptionFor('PD'));
    } else if (showCargo && activeTabNTCGPD === 'cargo') {
      CGPolicyDetailsListing();
      fetchCGPremium(getSubscriptionFor('CG'));
    } else if (activeTabNTCGPD === 'nonTruckingBobtail') {
      NTPolicyDetailsListing();
    }
    setCurrentAccordion('Accordion2');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabNTCGPD, setActiveTabNTCGPD]);


  const guestUserToken = GetGuestUserToken();

  const fetchPDPremium = async (pdSubscription) => {
    try {
      const headers = {
        Authorization: guestUserToken,
      };
      const params = {};

      const response = await getRequest(GET_PD_PREMIUM, params, headers);
      console.log('response', response);
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const fetchCGPremium = async (cgSubscription) => {
    try {
      const headers = {
        Authorization: guestUserToken,
      };
      const params = {};

      const response = await getRequest(GET_CG_PREMIUM, params, headers);
      console.log('response', response);
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const NTPolicyDetailsListing = async () => {
    try {
      const coverage_type = {
        coverage_type: 'nt',
      };
      const headers = {
        Authorization: guestUserToken,
      };
      const response = await getRequest(
        GET_POLICY_DETAILS,
        coverage_type,
        headers
      );
      setNTPolicyListingRepsonse(response.result);
    } catch (error) {
      console.log('API error:', error);
    } finally {
    }
  };

  const PDPolicyDetailsListing = async () => {
    try {
      const coverage_type = {
        coverage_type: 'pd',
      };
      const headers = {
        Authorization: guestUserToken,
      };
      const response = await getRequest(
        GET_POLICY_DETAILS,
        coverage_type,
        headers
      );
      setPDPolicyListingRepsonse(response.result);
    } catch (error) {
      console.log('API error:', error);
    } finally {
    }
  };

  const CGPolicyDetailsListing = async () => {
    try {
      const coverage_type = {
        coverage_type: 'cg',
      };
      const headers = {
        Authorization: guestUserToken,
      };
      const response = await getRequest(
        GET_POLICY_DETAILS,
        coverage_type,
        headers
      );
      setCGPolicyListingRepsonse(response.result);
    } catch (error) {
      console.log('API error:', error);
    } finally {
    }
  };

  return (
    <div>
      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={toggleSecondAccordion}>
          Non-Tucking Bobtail :
        </Accordion.Header>
        <Accordion.Body>
          {' '}
          <Tab.Container activeKey={activeTabNTCGPD}>
            <Nav variant="pills" className="nav-three">
            {showPhysicalDamage && (
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="physicalDamage"
                  onClick={() => handleTabChangeNTCGPD('physicalDamage')}
                >
                  <span className="d-none-mobile">Physical Damage</span>
                  <span className="d-none-desk">PD</span>
                </Nav.Link>
              </Nav.Item>
              )}
              {showCargo && (
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="cargo"
                  onClick={() => handleTabChangeNTCGPD('cargo')}
                >
                  Cargo
                </Nav.Link>
              </Nav.Item>
              )}
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="nonTruckingBobtail"
                  onClick={() => handleTabChangeNTCGPD('nonTruckingBobtail')}
                >
                  <span className="d-none-mobile">Non-Trucking/Bobtail</span>
                  <span className="d-none-desk">NT/Bobtail</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
            {showCargo && (
              <Tab.Pane eventKey="cargo">
                <Cargo
                  handleTabChange={handleTabChangeNTCGPD}
                  getSubscriptionFor={getSubscriptionFor}
                  CGpolicyListingRepsonse={CGpolicyListingRepsonse}
                  saveProfile={saveProfile}
                  CGPolicyDetailsListing={CGPolicyDetailsListing}
                  isDirectCheckout={isDirectCheckout}
                />
              </Tab.Pane>
              )}
              {showPhysicalDamage && (
              <Tab.Pane eventKey="physicalDamage">
                <PhysicalDamage
                  handleTabChange={handleTabChangeNTCGPD}
                  PDPolicyDetailsListing={PDPolicyDetailsListing}
                  getSubscriptionFor={getSubscriptionFor}
                  saveProfile={saveProfile}
                  PDpolicyListingRepsonse={PDpolicyListingRepsonse}
                  openFirstAccordion={openFirstAccordion}
                  isDirectCheckout={isDirectCheckout}
                />
              </Tab.Pane>
              )}
              <Tab.Pane eventKey="nonTruckingBobtail">
                <NonTrucking
                  handleTabChange={handleTabChangeNTCGPD}
                  setPolicyData={setPolicyData}
                  policyData={policyData}
                  getSubscriptionFor={getSubscriptionFor}
                  saveProfile={saveProfile}
                  NTPolicyDetailsListing={NTPolicyDetailsListing}
                  NTpolicyListingRepsonse={NTpolicyListingRepsonse}
                  handleModalShow={handleModalShow}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default NTCGPD;
