import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../utils/Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VehiclePlanSelectionSummary from './Section/VehiclePlanSelectionSummary/VehiclePlanSelectionSummary';
import Accordion from 'react-bootstrap/Accordion';
import OAPA from './PolicyAccordions/OAPA/OAPA';
import NTCGPD from './PolicyAccordions/NTCGPD/NTCGPD';
import NewGetQuote from '../NewGetQuote';
import VehicleListing from './Section/VehicleListing/VehicleListing';
import { useNavigate } from 'react-router-dom';
import MultiDriver from './Section/AddMultiDriver/MultiDriver';

const ChoosePlan = ({ saveProfile }) => {
  const { profileObject, setModalValue } = useContext(Context);
  const navigate = useNavigate();
  const [policyData, setPolicyData] = useState([]);
  const [paPolicyData, setPaPolicyData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);
  const [isSecondAccordionOpen, setIsSecondAccordionOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('occupationalAccident');
  const [activeTabNTCGPD, setActiveTabNTCGPD] = useState('physicalDamage');
  const [currentAccordion, setCurrentAccordion] = useState();

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
    navigate('choose-plan#OA');
    handleTabChange('occupationalAccident');
  };

  const toggleSecondAccordion = () => {
    setIsSecondAccordionOpen(!isSecondAccordionOpen);
    navigate('choose-plan#PD');
    handleTabChangeNTCGPD('physicalDamage');
  };

  const openSecondAccordion = () => {
    setIsFirstAccordionOpen(false);
    setIsSecondAccordionOpen(true);
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey === 'occupationalAccident') {
      navigate('choose-plan#OA');
    } else if (tabKey === 'passengerAccident') {
      navigate('choose-plan#PA');
    }
  };

  const handleTabChangeNTCGPD = (tabKey) => {
    setActiveTabNTCGPD(tabKey);
    if (tabKey === 'physicalDamage') {
      navigate('choose-plan#PD');
    } else if (tabKey === 'cargo') {
      navigate('choose-plan#cargo');
    } else if (tabKey === 'nonTruckingBobtail') {
      navigate('choose-plan#NT');
    } else if (tabKey === 'passengerAccident') {
      window.scrollTo(0, 0);
      navigate('choose-plan#PA');
    }
  };

  useEffect(() => {
    if (window.location.href.includes('quote/choose-plan#PD')) {
      openSecondAccordion();
      handleTabChangeNTCGPD('physicalDamage');
    } else if (window.location.href.includes('quote/choose-plan#PA')) {
      handleTabChange('passengerAccident');
    } else if (window.location.href.includes('quote/choose-plan#OA')) {
      handleTabChange('occupationalAccident');
    } else if (window.location.href.includes('quote/choose-plan#cargo')) {
      openSecondAccordion();
      handleTabChangeNTCGPD('cargo');
    } else if (window.location.href.includes('quote/choose-plan#NT')) {
      openSecondAccordion();
      handleTabChangeNTCGPD('nonTruckingBobtail');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalShow = (params) => {
    setModalOpen(true);
  };

  const openFirstAccordion = () => {
    setIsFirstAccordionOpen(true);
    setIsSecondAccordionOpen(false);
  };

  const getSubscriptionFor = (code_name) => {
    let subscription = {};
    if (profileObject?.programs[0]?.subscriptions) {
      profileObject.programs[0].subscriptions.forEach((subscriptionObj) => {
        if (subscriptionObj.coverage_type_code_name === code_name) {
          subscription = subscriptionObj;
        }
      });
    }
    return subscription;
  };

  const isDirectCheckout = (policy_name) => {
    const isOAorPA = policy_name === 'OA' || policy_name === 'PA';
    const isOAPlanSelected = getSubscriptionFor('OA');
    const isPAPlanSelected = getSubscriptionFor('PA');

    if (isOAorPA) {
      if (profileObject?.drivers?.length === 0) {
        setModalValue('add');
        handleModalShow();
      } else {
        window.scrollTo(0, 0);
        navigate('/quote/member-information');
      }
    } else {
      if (
        profileObject?.drivers?.length === 0 &&
        (Object.keys(isOAPlanSelected)?.length !== 0 ||
          Object.keys(isPAPlanSelected)?.length !== 0)
      ) {
        setModalValue('add');
        handleModalShow();
      } else {
        window.scrollTo(0, 0);
        navigate('/quote/member-information');
      }
    }
  };

  return (
    <>
      <h2 className="fw-bold mb-3 mt-md-4 mt-0 text-left ">
        Choose Policy Plans
      </h2>
      <Row className="mb-70-sm">
        <Col lg={8} className="mb-2">
          <div className="policy-mobile-container">
            <Accordion activeKey={isFirstAccordionOpen ? '0' : ''}>
              <OAPA
                setPaPolicyData={setPaPolicyData}
                setPolicyData={setPolicyData}
                policyData={policyData}
                paPolicyData={paPolicyData}
                toggleFirstAccordion={toggleFirstAccordion}
                openSecondAccordion={openSecondAccordion}
                saveProfile={saveProfile}
                getSubscriptionFor={getSubscriptionFor}
                handleTabChange={handleTabChange}
                handleTabChangeNTCGPD={handleTabChangeNTCGPD}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleModalShow={handleModalShow}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                isDirectCheckout={isDirectCheckout}
                setCurrentAccordion={setCurrentAccordion}
              />
            </Accordion>
            <Accordion activeKey={isSecondAccordionOpen ? '1' : ''}>
              <NTCGPD
                toggleSecondAccordion={toggleSecondAccordion}
                setPolicyData={setPolicyData}
                policyData={policyData}
                getSubscriptionFor={getSubscriptionFor}
                saveProfile={saveProfile}
                handleTabChangeNTCGPD={handleTabChangeNTCGPD}
                activeTabNTCGPD={activeTabNTCGPD}
                setActiveTabNTCGPD={setActiveTabNTCGPD}
                openFirstAccordion={openFirstAccordion}
                handleModalShow={handleModalShow}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                isDirectCheckout={isDirectCheckout}
                setCurrentAccordion={setCurrentAccordion}
              />
            </Accordion>
          </div>
        </Col>
        <Col lg={4} className="">
          <VehicleListing />
          <MultiDriver
            saveProfile={saveProfile}
            handleModalShow={handleModalShow}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            openSecondAccordion={openSecondAccordion}
            currentAccordion={currentAccordion}
          />
          <VehiclePlanSelectionSummary saveProfile={saveProfile} />
          <NewGetQuote />
        </Col>
      </Row>
    </>
  );
};

export default ChoosePlan;
